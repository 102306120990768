import { useToast } from '@hooks/useToast';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFileMutation } from '@hooks/useFiles';
import { useDocsAccess } from './useDocsAccess';

const INVALID_FILE_EXTENSIONS = ['.bat', '.exe', '.app', '.js', '.ts', '.py'];

export const useDropFiles = ({ recordId, stageId }: { recordId: number; stageId?: number }) => {
  const { showError } = useToast();
  const { canCreateFile } = useDocsAccess();

  const {
    uploadFiles: { mutateAsync: uploadFiles }
  } = useFileMutation();

  const onDropFiles = useCallback(
    async (acceptedFiles) => {
      if (!canCreateFile) {
        return;
      }

      const filteredFiles = acceptedFiles.filter((file) => {
        return !INVALID_FILE_EXTENSIONS.some((ext) => file.name.endsWith(ext));
      });

      if (filteredFiles.length === 0) {
        return;
      }

      if (acceptedFiles.some((file) => file.size / 1024 / 1024 > 350)) {
        // 350mb
        showError('Maximum file size is 350 MB');

        return;
      }

      await uploadFiles({
        files: acceptedFiles,
        isPrivate: true,
        projectId: recordId,
        stageId
      });
    },
    [stageId, recordId, showError, uploadFiles, canCreateFile]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog
  } = useDropzone({
    onDrop: onDropFiles,
    noClick: true,
    accept: {
      'image/*': [],
      'video/*': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/pdf': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'text/plain': []
    }
  });

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    openFileDialog
  };
};
